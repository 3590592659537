import axios from 'axios';
import React, { Component } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useHRStore } from 'store/hrStore';

const BenifitEdit = ({modalEdit,onToggleEdit,data,editSelectedIndex,selectedYear})=>{
  const { benifits,runEditSettings } = useHRStore(state => [state.benifits,state.runEditSettings])
  const [formData, setFormData] = useState({
    setting_name: "",
    setting_type: null,
    employer: null,
    employee: null,
    max: null,
    status: null,
    overall: null,
    active_year: null,
  })

    useEffect(() => {
        const value = data?.values.find(item => item.active_year === selectedYear)
        setFormData({ ...formData, 
            setting_name: data?.setting_name,
            setting_type: data?.setting_type,
            percentage: value?.percentage ? 1 : 0,
            status: value?.status,
        })
      }, [data,selectedYear])

  const { setting_name, setting_type, percentage, status } = formData

    const toggleEdit = useCallback(()=>onToggleEdit())
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    if (!modalEdit) {
        return null;
    }

    const handleSubmit = e => {
      e.preventDefault()

      toggleEdit()
      setFormData([])
    }

    return (
        <Col sm={6} md={4} xl={3}>
        <div className="my-4 text-center">
          <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
            <ModalHeader className="mt-0" toggle={toggleEdit}>
              <i
                className="typcn typcn-user-add-outline"
                style={{ fontSize: "35px" }}
              ></i>{" "}
              Edit Benefit
            </ModalHeader>
            <ModalBody>
              <CardBody>
                <CardTitle className="h4"></CardTitle>
                <p className="card-title-desc">{selectedYear} values</p>
                <CardBody>
                  <CardTitle className="h4"></CardTitle>
                  <p className="card-title-desc"></p>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            Benefit name
                        </label>
                        <div className="col-md-10">
                            <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Leave Name"
                            name="setting_name"
                            value={setting_name}
                            onChange={handleChange}
                            />
                        </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                        Benefit Type
                        </label>
                        <div className="col-md-10">
                        <select className="form-control"
                        name="setting_type" value={setting_type}>
                            <option disabled>Select</option>
                            <option value="Government">Government</option>
                            <option value="Company">Company</option>
                        </select>
                        </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                        Percentage
                        </label>
                        <div className="col-md-10">
                        <select className="form-control"
                        name="percentage" value={percentage} onChange={handleChange}>
                            <option disabled>Select</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                        </select>
                        </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                        Status
                        </label>
                        <div className="col-md-10">
                        <select className="form-control"
                        name="status" value={status} onChange={handleChange}>
                            <option disabled>Select</option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                        </select>
                        </div>
                    </Row>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-success" onClick={handleSubmit}>
                      Save
                    </button>
                    <button type="button" className="btn btn-danger" onClick={onToggleEdit}>
                      Close
                    </button>
                  </div>
                </CardBody>
              </CardBody>
            </ModalBody>
          </Modal>
        </div>
      </Col>
      )

}


export default BenifitEdit