import axios from 'axios';
import React, { Component } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useHRStore } from 'store/hrStore';

const LeaveEdit = ({modalEdit,onToggleEdit,data,selectedIndex})=>{
  const { leaves,runEditSettings } = useHRStore(state => [state.leaves,state.runEditSettings])
    const [formData, setFormData] = useState({
        leaveType: null,
        leaveDays:  null,
        leaveStatus: null,
    })

    useEffect(() => {
        setFormData({ ...formData, leaveDays: data?.days, leaveType: data?.leave_type })
      }, [data])

  const { leaveType, leaveDays,leaveStatus } = formData

    const toggleEdit = useCallback(()=>onToggleEdit())
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    if (!modalEdit) {
        return null;
    }

    const handleSubmit = e => {
      e.preventDefault()

        const newValue = leaves.filter((a,i)=> {
          if(i===selectedIndex){
            a.leave_type = leaveType;
            a.days = leaveDays;
          }
        })
        Object.assign(leaves,newValue)

      const updateData  = {
        value:leaves,
        "setting_id": 3,
        "setting_type": 3
      }
  
      runEditSettings(updateData)
      toggleEdit()
      setFormData([])
    }

    return (
        <Col sm={6} md={4} xl={3}>
        <div className="my-4 text-center">
          <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
            <ModalHeader className="mt-0" toggle={toggleEdit}>
              <i
                className="typcn typcn-user-add-outline"
                style={{ fontSize: "35px" }}
              ></i>{" "}
              Edit Leave
            </ModalHeader>
            <ModalBody>
              <CardBody>
                <CardTitle className="h4"></CardTitle>
                <p className="card-title-desc"></p>
                <CardBody>
                  <CardTitle className="h4"></CardTitle>
                  <p className="card-title-desc"></p>
                  <Row className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Leave Type
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Leave Name"
                      name="leaveType"
                      value={leaveType}
                      onChange={handleChange}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Number of Days
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Number of Days"
                      name="leaveDays"
                      value={leaveDays}
                      onChange={handleChange}
                    />
                  </div>
                </Row>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-success" onClick={handleSubmit}>
                      Save
                    </button>
                    <button type="button" className="btn btn-danger" onClick={onToggleEdit}>
                      Close
                    </button>
                  </div>
                </CardBody>
              </CardBody>
            </ModalBody>
          </Modal>
        </div>
      </Col>
      )

}


export default LeaveEdit