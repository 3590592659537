import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from "reactstrap"

import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import "chartist/dist/scss/chartist.scss"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classNames from "classnames"
import { useHRStore } from "store/hrStore"

const DepartmentList = () => {
  const [formData, setFormData] = useState({
    department_name: "",
  })

  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [selectedItem, setSelectedItem] = useState()

  const { department_name } = formData
  const { runGetDepartments } = useHRStore(state => [state.runGetDepartments])

  useEffect(() => {
    runGetDepartments()
  }, [])

  const handleChangeStatus = e => {
    setFormData(data => ({ ...data, status: e.target.value }))
  }

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleEditChange = e => {
    const { name, value } = e.target
    if (name === "status")
      return setSelectedItem(selected => ({ ...selected, status: value }))
    setSelectedItem(selected => ({ ...selected, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (department_name) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/department/add`,
          formData
        )
        .then(res => {
          setData([...data, res.data])
          setFormData({ department_name: "" })
          setmodalAdd(!modalAdd)
          removeBodyCss()
        })
        .catch(err => console.log(err))
    }
  }

  const handleUpdate = () => {
    const { department_id, department_name, department_head_id, status } =
      selectedItem

    const reqForm = {
      department_id,
      department_name,
      department_head_id,
      status,
    }

    if (department_name) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/department/edit`,
          reqForm
        )
        .then(res => {
          toggleEdit()
          setSelectedItem({
            department_name: "",
          })
          setRefresh(refresh + 1)
        })
        .catch(err => console.log(err))
    }
  }

  const handleEdit = dept => {
    setSelectedItem({ ...dept })
    toggleEdit()
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/department/get`)
      .then(res => {
        setData(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleAdd = () => {
    setFormData(data => ({ ...data, status: "1" }))
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const [modal_center, setmodal_center] = useState(false)

  document.title = "Department | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Department"
          />
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Department
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {data.length}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Employees
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      368{" "}
                      <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={10}>
                      <h2>List of Department</h2>
                    </Col>
                    <Col lg={2}>
                      <Button
                        onClick={toggleAdd}
                        size="sm"
                        color="primary"
                        className="waves-effect waves-light col-auto"
                      >
                        <i
                          className="typcn typcn-user-add-outline"
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        Add Department
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Department</th>
                          <th>Department Head</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.department_name}</td>
                            <td>On Going API</td>
                            <th>
                              <span
                                className={classNames("badge", {
                                  "bg-success": item.status === 1,
                                  "bg-danger": item.status === 0,
                                })}
                                style={{ width: "60px" }}
                              >
                                {item.status === 1 ? "Active" : "Inactive"}
                              </span>
                            </th>
                            <th>
                              <i
                                onClick={() => {
                                  handleEdit(item)
                                }}
                                className="typcn typcn-edit"
                                style={{ fontSize: "25px" }}
                              ></i>{" "}
                              <i
                                onClick={toggleDelete}
                                className="far fa-file-archive"
                                style={{ fontSize: "18px" }}
                              ></i>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* ADD MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Department
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <form>
                      <div className="form-group">
                        <Row className="mb-3">
                          <label
                            htmlFor="department_name"
                            className="col-md-2 col-form-label"
                          >
                            Department
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="text"
                              id="department_name"
                              placeholder="Enter Department"
                              name="department_name"
                              value={department_name}
                              onChange={handleChange}
                            />
                          </div>
                        </Row>
                      </div>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Department Head
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>--- Select Department Head ---</option>
                            <option>John Doe</option>
                            <option>Will Smith</option>
                            <option>Peter Parker</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="status"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={handleChangeStatus}
                            >
                              {/* <option>--- Select Status ---</option> */}
                              <option name="status" value={1}>
                                Active
                              </option>
                              {/* <option name="status" value={0}>
                                Inactive
                              </option> */}
                            </select>
                          </div>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleSubmit}>
                    Add
                  </Button>
                  <Button color="secondary" onClick={toggleAdd}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>
          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Deparment
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Department
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          id="department_name"
                          name="department_name"
                          value={selectedItem?.department_name || ""}
                          onChange={handleEditChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Department Head
                      </label>
                      <div className="col-md-10">
                        <select
                          name="department_head_id"
                          className="form-control"
                          onChange={handleEditChange}
                        >
                          <option>--- Select Department Head ---</option>
                          <option value="1">John Doe</option>
                          <option value="2">Will Smith</option>
                          <option value="3"> Peter Parker</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <select
                          className="form-control"
                          name="status"
                          value={selectedItem?.status}
                          onChange={handleEditChange}
                        >
                          <option disabled>--- Select Status ---</option>
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </select>
                      </div>
                    </Row>
                  </CardBody>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleUpdate()
                    }}
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggleEdit}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to Archive?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DepartmentList
