import axios from 'axios';
import React, { Component } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useHRStore } from 'store/hrStore';

const LeaveAdd = ({modalAdd,onToggleAdd})=>{
  const { leaves,runEditSettings } = useHRStore(state => [state.leaves,state.runEditSettings])
    const [formData, setFormData] = useState({
        leaveType: null,
        leaveDays: null,
    })
    
  const { leaveType, leaveDays } = formData

    const toggleAdd = useCallback(()=>onToggleAdd())
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    if (!modalAdd) {
        return null;
    }

    const handleSubmit = e => {
      e.preventDefault()
          const newValue = [...leaves, {
                  "days": leaveDays,
                  "leave_type": leaveType,
              }]
  
              const updateData  = {
                value:newValue,
                "setting_id": 3,
                "setting_type": 3
              }
  
      runEditSettings(updateData)
      toggleAdd()
      setFormData([])
  
    }

    return (
        <Col sm={6} md={4} xl={3}>
        <div className="my-4 text-center">
          <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
            <ModalHeader className="mt-0" toggle={toggleAdd}>
              <i
                className="typcn typcn-user-add-outline"
                style={{ fontSize: "35px" }}
              ></i>{" "}
              Add New Leave
            </ModalHeader>
            <ModalBody>
              <CardBody>
                <CardTitle className="h4"></CardTitle>
                <p className="card-title-desc"></p>
                <CardBody>
                  <CardTitle className="h4"></CardTitle>
                  <p className="card-title-desc"></p>
                  <Row className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Leave Type
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Leave Name"
                      name="leaveType"
                      value={leaveType}
                      onChange={handleChange}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Number of Days
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Number of Days"
                      name="leaveDays"
                      value={leaveDays}
                      onChange={handleChange}
                    />
                  </div>
                </Row>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-success" onClick={handleSubmit}>
                      Save
                    </button>
                    <button type="button" className="btn btn-danger" onClick={onToggleAdd}>
                      Close
                    </button>
                  </div>
                </CardBody>
              </CardBody>
            </ModalBody>
          </Modal>
        </div>
      </Col>
      )

}


export default LeaveAdd