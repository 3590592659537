import request from "./request"

export const getDesignations = () =>
  request({
    method: "get",
    url: `/api/designation/get`,
  })

export const getDepartments = () =>
  request({
    method: "get",
    url: `/api/department/get`,
  })

export const getSettings = () =>
  request({
    method: "get",
    url: `/api/setting/get`,
  })

export const editSettings = params =>
  request({
    method: "put",
    url: `/api/setting/edit`,
    data: params,
  })

// Employee API
  export const getEmployees = () =>
  request({
    method: "get",
    url: `/api/employee/get`,
  })

  export const addEmployee = (params) =>
  request({
    method: "put",
    url: `/api/employee/create`,
    data:params
  })

// Login API
export const login = params =>
  request({
    method: "put",
    url: `/api/auth/login`,
    data: params,
  })
