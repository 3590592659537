import React, { useState, useEffect } from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import Pie from "../AllCharts/echart/piechart"
import Line from "../AllCharts/echart/linechart"
import Select from "react-select"

import "chartist/dist/scss/chartist.scss"

import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"

import axios from "axios"
import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const AssetsList = () => {
  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState([])
  useEffect(() => {
    axios
      .get("https://hris-server.byteflaresolutions.com/api/asset-name/get")
      .then(res => {
        setData(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  const [formData, setFormData] = useState({
    asset_name: "",
  })

  const { asset_name } = formData

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (asset_name) {
      axios
        .put(
          "https://hris-server.byteflaresolutions.com/api/asset-name/add",
          formData
        )
        .then(res => {
          setData([...data, res.data])
          setFormData({
            asset_name: "",
          })
          setmodalAdd(!modalAdd)
          removeBodyCss()
        })
        .catch(err => console.log(err))
    }
  }

  const [selectedItem, setSelectedItem] = useState()

  const handleEdit = asset => {
    setSelectedItem({ ...asset })
    toggleEdit()
  }

  const handleEditChange = e => {
    const { name, value } = e.target
    setSelectedItem({ ...selectedItem, [name]: value })
  }

  const handleUpdate = () => {
    const { asset_name_id, asset_name, status } = selectedItem

    const reqForm = {
      asset_name_id,
      asset_name,
      status,
    }

    if (asset_name) {
      axios
        .put(
          `https://hris-server.byteflaresolutions.com/api/asset-name/edit`,
          reqForm
        )
        .then(res => {
          toggleEdit()
          setSelectedItem({
            asset_name: "",
          })
          setRefresh(refresh + 1)
        })
        .catch(err => console.log(err))
    }
  }

  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Year",
      options: [
        { label: "2019", value: "2019" },
        { label: "2020", value: "2020" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "All time", value: "All Time" },
      ],
    },
  ]

  document.title = "Assets | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <hr />
                  <Row>
                    <Col xl={3} md={6}>
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon1} alt="" />
                            </div>
                            <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                              Computers
                            </h5>
                            <h4 className="fw-medium font-size-24">
                              127{" "}
                              <i className="mdi mdi-arrow-up text-success ms-2"></i>
                            </h4>
                            <div className="mini-stat-label bg-success">
                              <p className="mb-0">+ 12%</p>
                            </div>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <Link to="#" className="text-white-50">
                                <i className="mdi mdi-arrow-right h5"></i>
                              </Link>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={3} md={6}>
                      <Card className="mini-stat bg-info text-white">
                        <CardBody>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                              Available Headsets
                            </h5>
                            <h4 className="fw-medium font-size-24">
                              31{" "}
                              <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                            </h4>
                            <div className="mini-stat-label bg-danger">
                              <p className="mb-0">- 28%</p>
                            </div>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <Link to="#" className="text-white-50">
                                <i className="mdi mdi-arrow-right h5"></i>
                              </Link>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={3} md={6}>
                      <Card className="mini-stat bg-danger text-white">
                        <CardBody>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon3} alt="" />
                            </div>
                            <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                              Occupied Computers
                            </h5>
                            <h4 className="fw-medium font-size-24">
                              72{" "}
                              <i className="mdi mdi-arrow-up text-success ms-2"></i>
                            </h4>
                            <div className="mini-stat-label bg-info">
                              <p className="mb-0"> 10%</p>
                            </div>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <Link to="#" className="text-white-50">
                                <i className="mdi mdi-arrow-right h5"></i>
                              </Link>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={3} md={6}>
                      <Card className="mini-stat bg-success text-white">
                        <CardBody>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon4} alt="" />
                            </div>
                            <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                              Total Costs
                            </h5>
                            <h4 className="fw-medium font-size-24">
                              ₱2,436,000{" "}
                              <i className="mdi mdi-arrow-up text-success ms-2"></i>
                            </h4>
                            <div className="mini-stat-label bg-warning">
                              <p className="mb-0">+ 84%</p>
                            </div>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <Link to="#" className="text-white-50">
                                <i className="mdi mdi-arrow-right h5"></i>
                              </Link>
                            </div>
                            <p className="text-white-50 mb-0 mt-1">
                              Since last year
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <h4 className="mt-0 header-title mb-4">
                            Assets Chart
                          </h4>
                          <div id="pie-chart" className="e-chart">
                            <Pie />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <h4 className="mt-0 header-title mb-4">
                            Asset Expenses
                          </h4>
                          <div id="line-chart" className="e-chart">
                            <Line />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <h2>Assets</h2>
                    <Col xs={2}>
                      <div className="mt-2">
                        <Select
                          value={selectedGroup}
                          onChange={() => {
                            handleSelectGroup()
                          }}
                          placeholder="Year"
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col />
                    <Col lg={2}>
                      <div className>
                        <Button
                          color="primary"
                          className="btn btn-primary mt-2 waves-effect waves-light"
                          onClick={toggleAdd}
                        >
                          <i className="fas fa-plus"></i> Add Asset
                        </Button>
                        <Button
                          color="primary"
                          className="btn btn-primary ms-2 mt-2 waves-effect waves-light"
                        >
                          <i className="fas fa-print"></i> Export
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Asset Name</th>
                          <th>Category</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total Expense</th>
                          <th>Available</th>
                          <th>Occupied</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.asset_name}</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <th>
                              <i
                                onClick={() => {
                                  handleEdit(item)
                                }}
                                className="typcn typcn-edit"
                                style={{ fontSize: "25px" }}
                              ></i>{" "}
                              <i
                                onClick={toggleDelete}
                                className="far fa-file-archive"
                                style={{ fontSize: "18px" }}
                              ></i>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* ADD MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Asset
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="department_name"
                          className="col-md-2 col-form-label"
                        >
                          Item Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            id="asset_name"
                            placeholder="Enter Item Name"
                            name="asset_name"
                            value={asset_name}
                            onChange={handleChange}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Category
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Laptop</option>
                            <option>Desktop</option>
                            <option>Peripherals</option>
                            <option>Printer</option>
                            <option>Mobile Phone</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Serial Number
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Asset Serial"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Purchase Date
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            placeholder="Enter Date"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Purchase Price
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Enter Price"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Description
                        </label>
                        <div className="col-md-10">
                          <textarea
                            id="txtAddress2Billing"
                            name="txtAddress2Billing"
                            rows="4"
                            className="form-control"
                          />
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleAdd}
                        >
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Asset
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Asset Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Asset Name"
                            id="asset_name"
                            name="asset_name"
                            value={selectedItem?.asset_name || ""}
                            onChange={handleEditChange}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Assets Head
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Date"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            // name="status"
                            // value={selectedItem?.status}
                            // onChange={handleEditChange}
                          >
                            <option disabled>--- Select Status ---</option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                          </select>
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            handleUpdate()
                          }}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleEdit}
                        >
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to delete?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssetsList
