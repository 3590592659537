import React, { useCallback, useState } from "react"
import axios from "axios"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap"

import "chartist/dist/scss/chartist.scss"

import { BENEFITS, DateFilter, Gender } from "constants/data"
import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`
const BenifitsVal = styled.input`
  table {
    width: 100%;
  }
`
const SelectYear = styled.div`
  display: flex;
  column-gap: 15px;
  width: 10rem;
  align-items: baseline;
`
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fm } from "helpers"
import classNames from "classnames"
import { useMemo } from "react"
import { useEffect } from "react"
import BenifitEdit from "components/modal/benifitEdit"

const BenefitsList = () => {
  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [refresh, setRefresh] = useState(0)
  
  const [benefitData, setBenefitData] = useState([])
  const [editSelectedItem, setEditSelectedItem] = useState();
  const [editSelectedIndex, setEditSelectedIndex] = useState();

  const [listData, setListData] = useState([])
  const [selectedItem, setSelectedItem] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectYear, setSelectYear] = useState(null);
  
  const [formData, setFormData] = useState({
    benifits_name: "",
    setting_type: null,
    employer: null,
    employee: null,
    max: null,
    status: null,
    overall: null,
    active_year: null,
    selectYears: null,
  })

  const { benifits_name, setting_type, employer,employee, max, status, overall, active_year, selectYears } = formData

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSelectYear(String(currentYear))
  }, [])

  useEffect(() => {
    const value = selectedItem?.values.filter(item => item.active_year === selectYear)
    setListData(value)
  }, [selectedItem,selectYear])

  const handleChangeYear = e => {
    setSelectYear(e.target.value)
  }

  // add-------------------
  const toggleAdd = () => {
    setFormData([])
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }
  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  // add end---------------

  // edit------------------
  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }
  const handleView = (benifit,index) => {
    setSelectedItem({ ...benifit })
    setSelectedIndex(index)
  }
  const handleEdit = (benifit,index) => {
    setEditSelectedItem({ ...benifit })
    setEditSelectedIndex(index)
    toggleEdit()
  }
  const handleEditChange = e => {
    const { name, value } = e.target
    setEditSelectedItem( selected=> ({ ...selected, [name]: value }));
    // if (name !== "setting_name")
    //   setEditSelectedItem(selected => ({ ...selected, : value }))
    // setEditSelectedItem({ someProperty: { ...editSelectedItem, active_year: value} });

    // setEditSelectedItem({
    //   editSelectedItem: Object.assign({}, editSelectedItem, {active_year: false})
    // });

    // var someProperty = {...editSelectedItem.values}
    // someProperty = {[name]: value};
    // this.setState({someProperty})
  }
  
  const handleEditValChange = (event) => {
    const { name, value } = event.target;

    console.log(editSelectedItem ,name,value)

    // setEditSelectedItem({
    //   ...editSelectedItem,
    //   values: {
    //     ...editSelectedItem.values,
    //     [name]: value
    //   }
    // });
  };
  
  const handleUpdate = () => {
    console.log('test submit',editSelectedItem)
    // const { department_id, department_name, department_head_id, status } =
    //   editSelectedItem

    // const reqForm = {
    //   department_id,
    //   department_name,
    //   department_head_id,
    //   status,
    // }

    // if (department_name) {
    //   axios
    //     .put(
    //       `${process.env.REACT_APP_API_URL}/api/setting/edit`,
    //       reqForm
    //     )
    //     .then(res => {
    //       toggleEdit()
    //       setEditSelectedItem()
    //       setRefresh(refresh + 1)
    //     })
    //     .catch(err => console.log(err))
    // }
  }
  // edit end-----------------

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/setting/get`)
      .then(res => {
        setBenefitData(res.data[0].value)
      })
      .catch(err => console.log(err))
  }, [refresh])

  const handleSubmit = e => {
    e.preventDefault()
        const newValue = [...benefitData, {
                "setting_name": benifits_name,
                "values": [
                    {
                        "active_year": Number(active_year),
                        "max": max,
                        "employee": null,
                        "employer": null,
                        "status": 1,
                        "overall": null
                    }
                ]
            }]

            const updateData  = {
              value:newValue,
              "setting_id": 1,
              "setting_type": 1
            }

      axios
        .put(`${process.env.REACT_APP_API_URL}/api/setting/edit`,updateData)
        .then(res => {
          toggleAdd()
          setRefresh(refresh + 1)
          setFormData([])
        })
        .catch(err => console.log(err))

  }

  const handleSubmitEdit = e => {
    e.preventDefault()

      const editdata = {
        "setting_id": 1,
        "setting_type": 1,
        "value": [
            {
                "setting_name": "philhealth",
                "values": [
                    {
                        "max": 30000,
                        "employee": 0.045,
                        "employer": 0.095,
                        "status": 1,
                        "overall": 0.14,
                        "active_year": "2023"
                    }
                ]
            }
        ]
      };

      axios
        .put(`${process.env.REACT_APP_API_URL}/api/setting/edit`,editdata)
        .then(res => {
          console.log('res',res)
          // setData([...data, res.data])
          // setFormData({ department_name: "", status: "" })
          // setmodalAdd(!modalAdd)
          // removeBodyCss()
        })
        .catch(err => console.log(err))

  }
  document.title = "Benefits | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="Settings"
            breadcrumbItem="Benefits"
          />
          <Row>
            <Col xl={5}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h2>Benefits</h2>
                    </Col>
                    <Col lg={6} className="text-end">
                      <Button
                        onClick={toggleAdd}
                        size="md"
                        color="primary"
                        className="waves-effect waves-light col-auto"
                      >
                        <i
                          className="typcn typcn-user-add-outline"
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        Add Benefits
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th>Benefit Type</th>
                          <th>Percentage</th>
                          <th className="text-center">Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {benefitData.map((b, i) => (
                          <tr key={i}
                          className={classNames({"bg-primary text-white" : selectedIndex===i})}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleView(b,i)
                          }}>
                            <td>{b.setting_name}</td>
                            <td>{b.type}</td>
                            <td
                              className={classNames("text-center", {
                                "text-success": b.percentage,
                                "text-info": !b.percentage,
                              })}
                            >
                              {b.percentage ? "YES" : "NO"}
                            </td>
                            <th className="text-center">
                              <span
                                className={classNames(
                                  "text-center d-flex align-items-center justify-content-center",
                                  b.values[0].status ? "text-success" : "text-warning"
                                )}
                              >
                                <i className="mdi mdi-circle-slice-8 mx-2" />
                                {b.values[0].status ? "ACTIVE" : "IN-ACTIVE"}
                              </span>
                            </th>
                            <th>
                              <i
                                onClick={() => {
                                  handleEdit(b,i)
                                }}
                                className="typcn typcn-edit"
                                style={{ fontSize: "25px" }}
                              ></i>
                              <i
                                onClick={toggleDelete}
                                className="typcn typcn-trash"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={7}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={4}>
                      <h2>{selectedItem?.setting_name}</h2>
                    </Col>
                    <Col lg={4}>
                    <SelectYear>
                      <label>Year</label>
                      <select className="form-control"
                          name="selectYear"
                          value={selectYear}
                          onChange={handleChangeYear}>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                      </select>
                    </SelectYear>
                    </Col>
                    <Col lg={4} className="text-end">
                      <Button
                        onClick={toggleAdd}
                        size="md"
                        color="primary"
                        className="waves-effect waves-light col-auto"
                      >
                        <i
                          className="typcn typcn-user-add-outline"
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        Add Data
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th className="text-center">Min - Max / Value</th>
                          <th>Contribution</th>
                          <th className="text-center">Total Contribution</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listData?.map((b, i) => (
                          <tr key={i}>
                            <th className="text-center">
                              {b.percentage
                                ? `${b.value}%`
                                : `${b.min ? fm.from(b.min) : "BELOW"} - ${
                                    b.max ? fm.from(b.max) : "ABOVE"
                                  }`}
                            </th>
                            <th className="text-start">
                              <div className="d-flex flex-row align-items-start justify-content-start">
                                {`Employer: ${fm.from(b.employer)}`}
                                <br />
                                {`Employee: ${fm.from(b.employee)}`}
                              </div>
                            </th>
                            <th className="text-center">
                              {fm.from(b.overall)}
                            </th>
                            <th>
                              <i
                                onClick={toggleView}
                                className="typcn typcn-user-outline"
                                style={{ fontSize: "25px" }}
                              ></i>{" "}
                              <i
                                onClick={() => {
                                  handleEdit(b,i)
                                }}
                                className="typcn typcn-edit"
                                style={{ fontSize: "25px" }}
                              ></i>
                              <i
                                onClick={toggleDelete}
                                className="typcn typcn-trash"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* VIEW MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalView} toggle={toggleView} size="lg">
                <ModalHeader className="mt-0" toggle={toggleView}>
                  View Benefit
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Benefit
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Benefit"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Benefit Type
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Enter Benefit Type"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label className="col-md-2 col-form-label">
                        Percentage
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option disabled>Select</option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Percentage Value
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Minimum Value
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Maximum Value
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label className="col-md-2 col-form-label">
                        Fixed Contribution
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option disabled>Select</option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Employer Contribution
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Employee Contribution
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* ADD MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Benefits
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Benefit
                        </label>
                        <div className="col-md-10">
                          
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Benefit"
                            name="benifits_name"
                            value={benifits_name}
                            onChange={handleChange}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                          Benefit Type
                        </label>
                        <div className="col-md-10">
                          <select className="form-control"
                          name="setting_type"
                          value={setting_type}
                          onChange={handleChange}>
                            <option disabled>Select</option>
                            <option value={0}>Government</option>
                            <option value={1}>Company</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Active Year
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Enter year"
                            name="active_year"
                            value={active_year}
                            onChange={handleChange}
                          />
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={handleSubmit}>
                          Save
                        </button>
                        <button type="button" className="btn btn-danger" onClick={toggleAdd}>
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <BenifitEdit modalEdit={modalEdit} onToggleEdit={toggleEdit} data={editSelectedItem} editSelectedIndex={editSelectedIndex} selectedYear={selectYear}/>
          {/* <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Benefit
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Benefit name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Benefit name"
                            name="setting_name"
                            value={editSelectedItem?.setting_name || ""}
                            onChange={handleEditChange}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                          Benefit Type
                        </label>
                        <div className="col-md-10">
                          <select className="form-control"
                          name="setting_type">
                            <option disabled>Select</option>
                            <option value={0}>Government</option>
                            <option value={1}>Company</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-3 col-form-label"
                        >
                          Contribution Settings
                        </label>
                        <div className="col-md-9">
                          <table>
                            <thead>
                              <tr>
                                <th>Year</th>
                                <th>employee</th>
                                <th>employer</th>
                                <th>max</th>
                              </tr>
                            </thead>
                            <tbody>
                            {editSelectedItem?.values.map((val, i) => (
                              <tr key={i}>
                                <td>{val.active_year}</td>
                                <td>
                                  <input
                                  className="form-control form-control-sm"
                                  type="number"
                                  name="employee"
                                  value={val.employee}
                                  onChange={handleEditValChange}
                                /></td>
                                <td>
                                  <input
                                  className="form-control form-control-sm"
                                  type="number"
                                  name="max"
                                  value={val.employer}
                                  onChange={handleEditValChange}
                                /></td>
                                <td>
                                  <input
                                  className="form-control form-control-sm"
                                  type="number"
                                  name="max"
                                  value={val.max}
                                  onChange={handleEditValChange}
                                />
                                </td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-success"
                        onClick={() => {
                          handleUpdate()
                        }}>
                          Save
                        </button>
                        <button type="button" className="btn btn-danger" onClick={toggleEdit}>
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col> */}

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to delete?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BenefitsList
