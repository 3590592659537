import React, { useState, useEffect } from "react"
import axios from "axios"
import classNames from "classnames"
import { format } from "date-fns"

import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const GoalType = () => {
  // GET LIST

  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState([])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/goal/get`)
      .then(res => {
        setData(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  // GET TYPE

  const [data1, setData1] = useState([])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/goal-type/get`)
      .then(res => {
        setData1(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  // ADD
  const [formData, setFormData] = useState({
    subject: "",
    description: "",
  })

  const { subject, progress, start_date, end_date, target_achievement } =
    formData

  const handleChangeStatus = e => {
    setFormData(data => ({
      ...data,
      status: 1,
      goal_type_id: e.target.value,
    }))
  }
  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (subject && target_achievement && progress && start_date && end_date) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/api/goal/add`, formData)
        .then(res => {
          setData([...data, res.data])
          setFormData({
            subject: "",
            target_achievement: "",
            progress: "",
          })
          setmodalAdd(!modalAdd)
          removeBodyCss()
          setRefresh(refresh + 1)
        })
        .catch(err => console.log(err))
    }
  }

  // EDIT

  const [selectedItem, setSelectedItem] = useState()

  const handleEditChange = e => {
    const { name, value } = e.target
    if (name === "status")
      return setSelectedItem(selected => ({ ...selected, status: value }))
    setSelectedItem(selected => ({ ...selected, [name]: value }))
  }

  const handleEditChange1 = e => {
    const { name, value } = e.target
    if (name === "goal_type_id")
      return setSelectedItem(selected => ({
        ...selected,
        goal_type_id: value,
      }))

    setSelectedItem(selected => ({ ...selected, [name]: value }))
  }

  const handleEdit = goalList => {
    setSelectedItem({ ...goalList })
    toggleEdit()
  }

  const handleUpdate = () => {
    const {
      goal_id,
      goal_type_id,
      subject,
      progress,
      start_date,
      end_date,
      target_achievement,
      status,
    } = selectedItem

    const reqForm = {
      goal_id,
      goal_type_id,
      subject,
      progress,
      start_date,
      end_date,
      target_achievement,
      status: status.toString(),
    }

    if (subject && target_achievement && progress && start_date && end_date) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/api/goal/edit`, reqForm)
        .then(res => {
          toggleEdit()
          setRefresh(refresh + 1)
        })
        .catch(err => console.log(err))
    }
  }
  // -

  const [drp_secondary, setDrp_secondary] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  document.title = "Goal | Goal List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Goal List"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>Goal List</h2>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={3}>
                      <FilterTxt
                        name="search"
                        type="text"
                        className="form-control w-100 h-100"
                        placeholder="Search..."
                      />
                    </Col>
                    <Col lg={5}>
                      <div className="btn-group mt-1">
                        <ButtonDropdown
                          isOpen={drp_secondary}
                          toggle={() => setDrp_secondary(!drp_secondary)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-outline-info"
                          >
                            <i
                              className="typcn typcn-th-list"
                              style={{ fontSize: "18px" }}
                            />{" "}
                            Select Status <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Active</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Inactive</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={toggleAdd}
                          size="md"
                          color="primary"
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="typcn typcn-folder-add"
                            style={{ fontSize: "18px" }}
                          ></i>{" "}
                          Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/* ADD MODAL */}
                  <Modal
                    isOpen={modalAdd}
                    toggle={toggleAdd}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={toggleAdd}>
                      Create Goal List
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Goal Type
                        </label>
                        <div className="mb-2">
                          <select
                            className="form-control"
                            onChange={handleChangeStatus}
                          >
                            <option>-- Please Select --</option>
                            {data1.map((item1, index) => (
                              <option
                                name="goal_type_id"
                                value={item1.goal_type_id}
                                key={index}
                              >
                                {item1.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label className="form-label">Subject</label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              id="subject"
                              placeholder="Enter Subject"
                              name="subject"
                              value={subject}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label className="form-label">
                            Target Achievement
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              id="target_achievement"
                              placeholder="Enter Target Achievement"
                              name="target_achievement"
                              value={target_achievement}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label className="form-label">Start Date</label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="date"
                              placeholder="Enter Start Date"
                              name="start_date"
                              value={start_date}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label className="form-label">End Date</label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="date"
                              placeholder="Enter End Date"
                              name="end_date"
                              value={end_date}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <Row className="mb-3">
                        <label className="col-md-12 col-form-label">
                          Description
                        </label>
                        <div className="col-md-12 mb-2">
                          <textarea
                            id="goal_type_description"
                            name="goal_type_description"
                            rows="4"
                            className="form-control"
                            value={goal_type_description}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </Row> */}
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label className="form-label">Progress</label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Progress"
                              name="progress"
                              value={progress}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label className="form-label">Status</label>
                          <div className="mb-2">
                            <select
                              className="form-control"
                              onChange={handleChangeStatus}
                            >
                              <option name="status" value={1}>
                                Active
                              </option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={handleSubmit}>
                        Add
                      </Button>
                      <Button color="secondary" onClick={toggleAdd}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  {/* EDIT MODAL */}
                  <Modal
                    isOpen={modalEdit}
                    toggle={toggleEdit}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={toggleEdit}>
                      Edit Goal List
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Goal Type
                        </label>
                        <div className="mb-2">
                          <select
                            className="form-control"
                            name="goal_type_id"
                            value={selectedItem?.goal_type_id}
                            onChange={handleEditChange1}
                          >
                            <option disabled>--- Select Deparment ---</option>
                            {data1.map((item1, index) => (
                              <option value={item1.goal_type_id} key={index}>
                                {item1.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label className="form-label">Subject</label>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="subject"
                              name="subject"
                              value={selectedItem?.subject || ""}
                              onChange={handleEditChange}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label className="form-label">
                            Target Achievement
                          </label>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="target_achievement"
                              name="target_achievement"
                              value={selectedItem?.target_achievement || ""}
                              onChange={handleEditChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label className="form-label">Start Date</label>
                          <div className="mb-2">
                            <input
                              type="date"
                              className="form-control"
                              value={selectedItem?.start_date || ""}
                              id="start_date"
                              name="start_date"
                              onChange={handleEditChange}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label className="form-label">End Date</label>
                          <div className="mb-2">
                            <input
                              type="date"
                              className="form-control"
                              value={selectedItem?.end_date || ""}
                              id="end_date"
                              name="end_date"
                              onChange={handleEditChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label className="form-label">Progress</label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              name="progress"
                              value={selectedItem?.progress}
                              onChange={handleEditChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Description
                        </label>
                        <div className="col-md-10 mb-2">
                          <textarea
                            id="txtAddress1Billing"
                            name="txtAddress1Billing"
                            rows="4"
                            className="form-control"
                            placeholder=""
                          ></textarea>
                        </div>
                      </Row> */}
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label className="form-label">Status</label>
                          <div className="mb-2">
                            <select
                              className="form-control"
                              name="status"
                              value={selectedItem?.status}
                              onChange={handleEditChange}
                            >
                              <option disabled>--- Select Status ---</option>
                              <option value={1}>Active</option>
                              <option value={0}>Inactive</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleUpdate()
                        }}
                      >
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={toggleEdit}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  {/* DELETE MODAL */}

                  <Col sm={6} md={4} xl={3}>
                    <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
                      <ModalHeader className="mt-0" toggle={toggleDelete}>
                        Are you sure want to delete Goal List?
                      </ModalHeader>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger">
                          Delete
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleDelete}
                        >
                          Close
                        </button>
                      </div>
                    </Modal>
                  </Col>

                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Goal Type</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Target Achievement</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">End Date</th>
                          <th scope="col">Description</th>
                          <th scope="col">Status</th>
                          <th scope="col">Progress</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <th scope="row">{item.goal_type_name}</th>
                            <td>{item.subject}</td>
                            <td>{item.target_achievement}</td>
                            <td>
                              {format(
                                new Date(item.created_date),
                                "MM/dd/yyyy"
                              )}
                            </td>
                            <td>
                              {format(new Date(item.end_date), "MM/dd/yyyy")}
                            </td>
                            <td>{item.goal_type_description}</td>
                            <td>
                              <span
                                className={classNames("badge", {
                                  "bg-success": item.status === 1,
                                  "bg-danger": item.status === 0,
                                })}
                                style={{ width: "60px" }}
                              >
                                {item.status === 1 ? "Active" : "Inactive"}
                              </span>
                            </td>
                            <td>{`${item.progress} %`}</td>
                            <th>
                              <i
                                onClick={() => {
                                  handleEdit(item)
                                }}
                                className="typcn typcn-edit"
                                style={{ fontSize: "25px" }}
                              ></i>{" "}
                              <i
                                onClick={toggleDelete}
                                className="typcn typcn-trash"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GoalType
