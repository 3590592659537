import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import { useEffect } from "react"

export const useAuth = () => {
  const history = useNavigate()

  // useEffect(() => {
  //   const accessToken = Cookies.get("h_tkn")
  //   if (!accessToken) history("/login")
  // }, [history])
}
