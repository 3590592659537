import axios from "axios"
import { nanoid } from "nanoid"
// import md5 from "md5"

const TIMEOUT = 15000
const queueMap = new Map()

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_I_ENV === "dev"
      ? "/"
      : process.env.REACT_APP_BASE_URL,
  timeout: TIMEOUT,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
})

console.log(instance)

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    console.error("Error:", error)
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    console.log("Response:", response.data)
    if (response.data.success && response.data.token)
      setAuthorizationHeader(response.data.token)
    return response
  },
  error => {
    console.error("Error:", error)
    return Promise.reject(error)
  }
)

const setAuthorizationHeader = token => {
  if (token) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`
  } else {
    delete instance.defaults.headers.common["Authorization"]
  }
}

function request(config) {
  const controller = new AbortController()
  const key = nanoid()
  queueMap.set(key, controller)

  if (!config.headers) config.headers = {}

  config.headers["x-request-id"] = key

  return instance.request({ ...config, signal: controller.signal })
}

export default request
