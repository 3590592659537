import React, { useState, useEffect } from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

import "chartist/dist/scss/chartist.scss"

import classNames from "classnames"

import axios from "axios"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const AssetCategoryList = () => {
  const [customActiveTab, setCustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) setCustomActiveTab(tab)
  }

  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState([])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/asset-type/get`)
      .then(res => {
        setData(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  const [formData, setFormData] = useState({
    type_name: "",
  })

  const [data1, setData1] = useState([])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/asset-name/get`)
      .then(res => {
        setData1(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  const { type_name } = formData

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (type_name) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/api/asset-type/add`, formData)
        .then(res => {
          setData([...data, res.data])
          setFormData({
            type_name: "",
          })
          setRefresh(refresh + 1)
          setmodalAdd(!modalAdd)
          removeBodyCss()
        })
        .catch(err => console.log(err))
    }
  }

  const [selectedItem, setSelectedItem] = useState()

  const handleEdit = asset => {
    setSelectedItem({ ...asset })
    toggleEdit()
  }

  const handleEditChange = e => {
    const { name, value } = e.target
    setSelectedItem({ ...selectedItem, [name]: value })
  }

  const handleUpdate = () => {
    const { type_id, type_name, status } = selectedItem

    const reqForm = {
      type_id,
      type_name,
      status,
    }

    if (type_name) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/api/asset-type/edit`, reqForm)
        .then(res => {
          toggleEdit()
          setSelectedItem({
            type_name: "",
          })
          setRefresh(refresh + 1)
        })
        .catch(err => console.log(err))
    }
  }

  const [formData1, setFormData1] = useState({
    asset_name: "",
  })

  const { asset_name } = formData1

  const handleChange1 = e => {
    setFormData1({ ...formData1, [e.target.name]: e.target.value })
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    if (asset_name) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/api/asset-name/add`, formData1)
        .then(res => {
          setData([...data, res.data])
          setFormData1({
            asset_name: "",
          })
          setRefresh(refresh + 1)
          setmodalAdd1(!modalAdd1)
          removeBodyCss()
        })
        .catch(err => console.log(err))
    }
  }

  const [selectedItem1, setSelectedItem1] = useState()

  const handleEdit1 = assetName => {
    setSelectedItem1({ ...assetName })
    toggleEdit1()
  }

  const handleEditChange1 = e => {
    const { name, value } = e.target
    setSelectedItem1({ ...selectedItem1, [name]: value })
  }

  const handleUpdate1 = () => {
    const { asset_name_id, asset_name, status } = selectedItem1

    const reqForm = {
      asset_name_id,
      asset_name,
      status,
    }

    if (asset_name) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/api/asset-name/edit`, reqForm)
        .then(res => {
          toggleEdit1()
          setSelectedItem1({
            asset_name: "",
          })
          setRefresh(refresh + 1)
        })
        .catch(err => console.log(err))
    }
  }

  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalAdd1, setmodalAdd1] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [modalEdit1, setmodalEdit1] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }
  const toggleAdd1 = () => {
    setmodalAdd1(!modalAdd1)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleEdit1 = () => {
    setmodalEdit1(!modalEdit1)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  document.title = "Assets Category | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs maintitle="HRIS" title="Assets" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          Asset Category
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block">Asset Name</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row className="mt-4">
                        <Col lg={9}>
                          <h2>Category</h2>
                        </Col>
                        <Col lg={3}>
                          <Button
                            onClick={toggleAdd}
                            size="sm"
                            color="primary"
                            className="waves-effect waves-light col-auto"
                          >
                            <i
                              className="typcn typcn-user-add-outline"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                            Add Category
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                      <div className="table-responsive">
                        <Table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>Asset Category</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => (
                              <tr key={index}>
                                <td>{item.type_name}</td>
                                <th>
                                  <i
                                    onClick={() => {
                                      handleEdit(item)
                                    }}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDelete}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                  </TabContent>
                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="2">
                      <Row>
                        <Col lg={9}>
                          <h2>Asset Name</h2>
                        </Col>
                        <Col lg={3}>
                          <Button
                            onClick={toggleAdd1}
                            size="sm"
                            color="primary"
                            className="waves-effect waves-light col-auto"
                          >
                            <i
                              className="typcn typcn-user-add-outline"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                            Add Asset Name
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                      <div className="table-responsive">
                        <Table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>Asset Name</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data1.map((item, index) => (
                              <tr key={index}>
                                <td>{item.asset_name}</td>
                                <th>
                                  <i
                                    onClick={() => {
                                      handleEdit1(item)
                                    }}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDelete}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* ADD MODAL CATEGORY*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Category
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Category Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            id="type_name"
                            placeholder="Enter Asset Catergory"
                            name="type_name"
                            value={type_name}
                            onChange={handleChange}
                          />
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleAdd}
                        >
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* ADD MODAL ASSET*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd1} toggle={toggleAdd1} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd1}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Asset Name
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Asset Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            id="asset_name"
                            placeholder="Enter Asset Name"
                            name="asset_name"
                            value={asset_name}
                            onChange={handleChange1}
                          />
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit1}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleAdd1}
                        >
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL CATEGORY*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Category
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Assets
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Asset Name"
                            id="type_name"
                            name="type_name"
                            value={selectedItem?.type_name || ""}
                            onChange={handleEditChange}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Assets Head
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Number of Days"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            // name="status"
                            // value={selectedItem?.status}
                            // onChange={handleEditChange}
                          >
                            <option disabled>--- Select Status ---</option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                          </select>
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            handleUpdate()
                          }}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleEdit}
                        >
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>
          {/* EDIT MODAL NAME*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit1} toggle={toggleEdit1} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit1}>
                  Edit Asset Name
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Asset Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Asset Name"
                            id="asset_name"
                            name="asset_name"
                            value={selectedItem1?.asset_name || ""}
                            onChange={handleEditChange1}
                            Asset
                            Category
                          />
                        </div>
                      </Row>
                      {/* <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Assets Head
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Number of Days"
                          />
                        </div>
                      </Row> */}
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            // name="status"
                            // value={selectedItem?.status}
                            // onChange={handleEditChange}
                          >
                            <option disabled>--- Select Status ---</option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                          </select>
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            handleUpdate1()
                          }}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleEdit1}
                        >
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to delete?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssetCategoryList
