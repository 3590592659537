import { useRequest } from "ahooks"
import { createGlobalStore } from "hox"
import { getDesignations, getDepartments, login } from "../utils/api"
import { getSettings, editSettings } from "../utils/api"
import { getEmployees, addEmployee } from "../utils/api"

const [store, getStore] = createGlobalStore(() => {
  const { data: designations, run: runGetDesignations } = useRequest(
    () => getDesignations(),
    {
      refreshDeps: [],
    }
  )

  const { data: departments, run: runGetDepartments } = useRequest(
    () => getDepartments(),
    {
      refreshDeps: [],
    }
  )

  const {
    data: settings,
    run: runGetSettings,
    runAsync: runAsyncGetSettings,
    loading: loadingSettings,
  } = useRequest(() => getSettings(), {
    refreshDeps: [],
    cacheKey: "cacheKey-demo",
    refreshOnWindowFocus: true,
  })

  const { run: runEditSettings } = useRequest(params => editSettings(params), {
    refreshDeps: [],
  })

  // Login ActionStore
  const { runAsync: loginUser } = useRequest(params => login(params), {
    refreshDeps: [],
  })

  const { data: employees, run: runGetEmployees } = useRequest(
    () => getEmployees(),
    {
      refreshDeps: [],
    }
  )

  const { run: runAddEmployees } = useRequest((params) => addEmployee(params), {
    refreshDeps: [],
  })

  const benifits = settings?.data[0]?.value
  const companyDetails = settings?.data[1]?.value
  const leaves = settings?.data[2]?.value
  const holidays = settings?.data[3]?.value
console.log('test benifits',benifits)
  return {
    designations,
    runGetDesignations,
    departments,
    runGetDepartments,
    settings,
    runEditSettings,
    holidays,
    leaves,
    benifits,
    loginUser,
    runGetEmployees,
    runAddEmployees,
    employees,
  }
})

export { store as useHRStore, getStore as getHRStore }
