import axios from 'axios';
import React, { Component } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useHRStore } from 'store/hrStore';

const HolidayEdit = ({modalEdit,onToggleEdit,data,selectedIndex})=>{
  const { holidays,runEditSettings } = useHRStore(state => [state.holidays,state.runEditSettings])
    const [formData, setFormData] = useState({
        holiday_date: null,
        holiday_name: null,
        holiday_type: null,
    })

    useEffect(() => {
        console.log('test data',data)
        setFormData({ ...formData, 
            holiday_date: data?.holiday_date,
            holiday_name: data?.holiday_name,
            holiday_type: data?.holiday_type,
        })
      }, [data])

  const { holiday_date, holiday_name, holiday_type } = formData

    const toggleEdit = useCallback(()=>onToggleEdit())
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    if (!modalEdit) {
        return null;
    }

    const handleSubmit = e => {
      e.preventDefault()
      console.log('test',formData)

      const newValue = holidays.filter((a,i)=> {
        if(i===selectedIndex){
          a.holiday_date = holiday_date;
          a.holiday_name = holiday_name;
          a.holiday_type = holiday_type;
        }
      })
      Object.assign(holidays,newValue)

        const updateData  = {
        value:holidays,
        "setting_id": 4,
        "setting_type": 4
        }
    
        runEditSettings(updateData)
        toggleEdit()
        setFormData([])
    }

    return (
        <Col sm={6} md={4} xl={3}>
        <div className="my-4 text-center">
          <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
            <ModalHeader className="mt-0" toggle={toggleEdit}>
              <i
                className="typcn typcn-user-add-outline"
                style={{ fontSize: "35px" }}
              ></i>{" "}
              Edit Holiday
            </ModalHeader>
            <ModalBody>
              <CardBody>
                <CardTitle className="h4"></CardTitle>
                <p className="card-title-desc"></p>
                <CardBody>
                  <CardTitle className="h4"></CardTitle>
                  <p className="card-title-desc"></p>
                  <Row className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Holiday
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Holiday Name"
                      name="holiday_name"
                      value={holiday_name}
                      onChange={handleChange}
                    />
                  </div>
                </Row>
                <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                        Holiday Type
                        </label>
                        <div className="col-md-10">
                        <select className="form-control"
                        name="holiday_type" value={holiday_type} onChange={handleChange}>
                            <option value="Regular">Regular</option>
                            <option value="Special Non-working">Special Non-working</option>
                            <option value="Special Working Holiday">Special Working Holiday</option>
                        </select>
                        </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Date
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="date"
                      placeholder="Enter Date"
                      name="holiday_date"
                      value={holiday_date}
                      onChange={handleChange}
                    />
                  </div>
                </Row>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-success" onClick={handleSubmit}>
                      Update
                    </button>
                    <button type="button" className="btn btn-danger" onClick={onToggleEdit}>
                      Close
                    </button>
                  </div>
                </CardBody>
              </CardBody>
            </ModalBody>
          </Modal>
        </div>
      </Col>
      )

}


export default HolidayEdit