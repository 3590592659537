import React, { useState, useEffect } from "react"
import axios from "axios"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap"
import "chartist/dist/scss/chartist.scss"

import classNames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const AssetAssignmentList = () => {
  // GET LIST
  // TO ADD,EDIT,ENHANCE UI
  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState([])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/asset/get`)
      .then(res => {
        setData(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  document.title = "Asset Assigment | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs maintitle="HRIS" title="MAIN" breadcrumbItem="Asset" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <p className="card-title-desc">List of Asset Assignment</p>
                  </Row>
                  <hr />
                  <Row>
                    <h2>Asset Assignment</h2>
                    <Col />
                    <Col lg={2}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={toggleAdd}
                          size="md"
                          color="primary"
                          // outline
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="fas fa-plus"
                            style={{ fontSize: "18px" }}
                          ></i>{" "}
                          Assign Asset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Asset</th>
                          <th>Employee</th>
                          <th>Serial Number</th>
                          <th>Asset Type</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.asset_name_id}</td>
                            <td>{item.imei}</td>
                            <td>LGJFULPDYY</td>
                            <td>Computer</td>
                            <td>
                              <span
                                className={classNames("badge", {
                                  "bg-success": item.status === 1,
                                  "bg-danger": item.status === 0,
                                })}
                                style={{ width: "60px" }}
                              >
                                {item.status === 1 ? "Active" : "Inactive"}
                              </span>
                            </td>
                            <th>
                              <i
                                onClick={toggleEdit}
                                className="typcn typcn-edit"
                                style={{ fontSize: "25px" }}
                              ></i>{" "}
                              <i
                                onClick={toggleDelete}
                                className="typcn typcn-trash"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Leave
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Asset
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Leave Name"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Asset Head
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Number of Days"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Select Status"
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to delete?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssetAssignmentList
