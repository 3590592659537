import React, { useEffect, useState } from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  FormFeedback,
} from "reactstrap"
import { Link } from "react-router-dom"

import axios, { Axios } from "axios"

import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import "chartist/dist/scss/chartist.scss"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classNames from "classnames"
import { useHRStore } from "store/hrStore"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

const DesignationList = () => {
  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      designation_name: "",
    },
    validationSchema: Yup.object().shape({
      designation_name: Yup.string().required("This value is required"),
    }),
    onSubmit: values => {
      console.log("values", values)
    },
  })

  const [formData, setFormData] = useState({
    designation_name: "",
    status: null,
    department_id: null,
  })

  const [data, setData] = useState([])
  const [data1, setData1] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [selectedItem, setSelectedItem] = useState()

  const { runGetDesignations } = useHRStore(state => [state.runGetDesignations])

  useEffect(() => {
    runGetDesignations()
  }, [])

  const { designation_name, status, department_id } = formData

  const handleChangeStatus = e => {
    setFormData(data => ({
      ...data,
      status: 1,
      department_id: e.target.value,
    }))
  }

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleEditChange = e => {
    const { name, value } = e.target
    if (name === "status")
      return setSelectedItem(selected => ({ ...selected, status: value }))

    setSelectedItem(selected => ({ ...selected, [name]: value }))
  }

  const handleEditChange1 = e => {
    const { name, value } = e.target
    if (name === "department_id")
      return setSelectedItem(selected => ({
        ...selected,
        department_id: value,
      }))

    setSelectedItem(selected => ({ ...selected, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (designation_name) {
      axios
        .put(
          "https://hris-server.byteflaresolutions.com/api/designation/add",
          formData
        )
        .then(res => {
          setData([...data, res.data])
          setFormData({
            designation_name: "",
            department_id: "",
          })
          setmodalAdd(!modalAdd)
          removeBodyCss()
          setRefresh(refresh + 1)
        })
        .catch(err => console.log(err))
    }
  }

  const handleEdit = des => {
    setSelectedItem({ ...des })
    toggleEdit()
  }

  const handleUpdate = () => {
    const {
      designation_id,
      designation_name,
      department_id,
      // department_name,
      status,
    } = selectedItem

    const reqForm = {
      designation_id,
      designation_name,
      department_id,
      // department_name,
      status,
    }

    if (designation_name) {
      axios
        .put(
          `https://hris-server.byteflaresolutions.com/api/designation/edit`,
          reqForm
        )
        .then(res => {
          toggleEdit()
          setSelectedItem({
            designation_name: "",
          })
          setRefresh(refresh + 1)
        })
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    axios
      .get("https://hris-server.byteflaresolutions.com/api/designation/get")
      .then(res => {
        setData(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  useEffect(() => {
    axios
      .get("https://hris-server.byteflaresolutions.com/api/department/get")
      .then(res => {
        setData1(res.data)
      })
      .catch(err => console.log(err))
  }, [refresh])

  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  document.title = "Designation | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Designation"
          />
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Designation
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {data.length}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Employees
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      368{" "}
                      <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={10}>
                      <h2>List of Designation</h2>
                    </Col>
                    <Col lg={2}>
                      <Button
                        onClick={toggleAdd}
                        size="sm"
                        color="primary"
                        className="waves-effect waves-light col-auto"
                      >
                        <i
                          className="typcn typcn-user-add-outline"
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        Add Designation
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Designation</th>
                          <th>Department</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.designation_name}</td>
                            <td>{item.department_name}</td>
                            <th>
                              <span
                                className={classNames("badge", {
                                  "bg-success": item.status === 1,
                                  "bg-danger": item.status === 0,
                                })}
                                style={{ width: "60px" }}
                              >
                                {item.status === 1 ? "Active" : "Inactive"}
                              </span>
                            </th>
                            <th>
                              {/* <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "} */}
                              <i
                                onClick={() => {
                                  handleEdit(item)
                                }}
                                className="typcn typcn-edit"
                                style={{ fontSize: "25px" }}
                              ></i>{" "}
                              <i
                                onClick={toggleDelete}
                                className="far fa-file-archive"
                                style={{ fontSize: "18px" }}
                              ></i>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* ADD MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Designation
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Designation
                        </label>
                        <div className="col-md-10">
                          <Input
                            className="form-control"
                            type="text"
                            id="designation_name"
                            placeholder="Enter Designation"
                            name="designation_name"
                            value={designation_name}
                            onChange={handleChange}
                            // onBlur={validationType.handleBlur}
                            // invalid={
                            //   validationType.touched.designation_name &&
                            //   validationType.errors.designation_name
                            //     ? true
                            //     : false
                            // }
                          />
                          {/* {validationType.touched.designation_name &&
                          validationType.errors.designation_name ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.designation_name}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Department
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            onChange={handleChangeStatus}
                          >
                            <option>--- Select Deparment ---</option>
                            {data1.map((item1, index) => (
                              <option
                                name="department_id"
                                value={item1.department_id}
                                key={index}
                              >
                                {item1.department_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            onChange={handleChangeStatus}
                          >
                            {/* <option>--- Select Status ---</option> */}
                            <option name="status" value={1}>
                              Active
                            </option>
                            {/* <option name="status" value={0}>
                              Inactive
                            </option> */}
                          </select>
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleAdd}
                        >
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Designation
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Designation
                        </label>
                        <div className="col-md-10">
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control"
                              id="designation_name"
                              name="designation_name"
                              value={selectedItem?.designation_name || ""}
                              onChange={handleEditChange}
                            />
                          </div>
                        </div>
                      </Row>
                      {/* <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Department
                        </label>
                        <div className="col-md-10">
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control"
                              id="status"
                              placeholder="Enter status"
                              name="status"
                              value={selectedItem?.department_name || ""}
                              onChange={handleEditChange}
                            />
                          </div>
                        </div>
                      </Row> */}
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Department
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            name="department_id"
                            value={selectedItem?.department_id}
                            onChange={handleEditChange1}
                          >
                            <option disabled>--- Select Deparment ---</option>
                            {data1.map((item1, index) => (
                              <option value={item1.department_id} key={index}>
                                {item1.department_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            name="status"
                            value={selectedItem?.status}
                            onChange={handleEditChange}
                          >
                            <option disabled>--- Select Status ---</option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                          </select>
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            handleUpdate()
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleEdit}
                        >
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to Archive?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DesignationList
